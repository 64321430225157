import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";


interface Data {
   id: string;
   order: number;
   description: string;
   mechanical_bikes: number;
   electrical_bikes: number;
   slots: number;
   status: number;
}


interface Rows {
   data: Data[];
   readonly onCNAB: Function;
   readonly onHome: Function;
   readonly onMarbella: Function;
   readonly onRefresh: Function;
   readonly refreshable: boolean;
   readonly updated: string;
}


interface HeadCell {
   disablePadding: boolean;
   id: keyof Data;
   label: string;
   numeric: boolean;
}


const headCells: HeadCell[] = [
   { id: "id", numeric: false, disablePadding: true, label: "id" },
   { id: "order", numeric: true, disablePadding: false, label: "order" },
   { id: "description", numeric: false, disablePadding: false, label: "description" },
   { id: "slots", numeric: true, disablePadding: true, label: "slots" },
   { id: "mechanical_bikes", numeric: true, disablePadding: true, label: "mechanical" },
   { id: "electrical_bikes", numeric: true, disablePadding: true, label: "electrical" },
   { id: "status", numeric: true, disablePadding: false, label: "status" },
];


// https://material-ui.com/components/tables/#sorting-amp-selecting
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}


type Order = "asc" | "desc";


function getComparator<Key extends keyof any>(
   order: Order,
   orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
   return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
   classes: ReturnType<typeof useStyles>;
   numSelected: number;
   onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
}


function EnhancedTableHead(props: EnhancedTableProps) {
   const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
   const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            <TableCell padding="checkbox">
               <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
               />
            </TableCell>
            {headCells.map((headCell) => (
               <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === headCell.id ? order : false}
               >
                  <TableSortLabel
                     active={orderBy === headCell.id}
                     direction={orderBy === headCell.id ? order : "asc"}
                     onClick={createSortHandler(headCell.id)}
                  >
                     {headCell.label}
                     {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                           {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </span>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}


const useToolbarStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(1),
      },
      highlight:
         theme.palette.type === "light"
            ? {
               color: theme.palette.secondary.main,
               backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
               color: theme.palette.text.primary,
               backgroundColor: theme.palette.secondary.dark,
            },
      title: {
         flex: "1 1 100%",
      },
   }),
);


interface EnhancedTableToolbarProps {
   readonly numSelected: number;
   readonly onRefresh: Function;
   readonly refreshable: boolean;
   readonly updated: string;
}


const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
   const classes = useToolbarStyles();
   const { numSelected, onRefresh, refreshable, updated } = props;

   const handleRefresh = () => {
      onRefresh();
   };

   return (
      <Toolbar
         className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
         })}
      >
         {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
               {numSelected} selected
            </Typography>
         ) : (
               <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                  Updated {updated} <Button onClick={handleRefresh} disabled={!refreshable} variant="contained">Refresh</Button>
               </Typography>
            )}
      </Toolbar>
   );
};


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: "100%",
      },
      paper: {
         width: "100%",
         marginBottom: theme.spacing(2),
      },
      table: {
         minWidth: 750,
      },
      visuallyHidden: {
         border: 0,
         clip: "rect(0 0 0 0)",
         height: 1,
         margin: -1,
         overflow: "hidden",
         padding: 0,
         position: "absolute",
         top: 20,
         width: 1,
      },
   }),
);


function TableBicing( props: Rows ) {
   const rows = props.data;
   const updated = props.updated;
   const onRefresh = props.onRefresh;
   const refreshable = props.refreshable;
   const classes = useStyles();
   const [order, setOrder] = React.useState<Order>("asc");
   const [orderBy, setOrderBy] = React.useState<keyof Data>("order");
   const [selected, setSelected] = React.useState<string[]>([]);
   const [page, setPage] = React.useState(0);
   const [dense, setDense] = React.useState(true);
   const [cnab, setCNAB] = React.useState(true);
   const [marbella, setMarbella] = React.useState(true);
   const [home, setHome] = React.useState(true);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
   };

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelecteds = rows.map((n) => n.id);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(event.target.checked);
   };

   const handleChangeCNAB = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCNAB(event.target.checked);
      props.onCNAB(event.target.checked);
   };

   const handleChangeMarbella = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMarbella(event.target.checked);
      props.onMarbella(event.target.checked);
   };

   const handleChangeHome = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHome(event.target.checked);
      props.onHome(event.target.checked);
   };

   const isSelected = (id: string) => selected.indexOf(id) !== -1;

   const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

   return (
      <div className={classes.root}>
         <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} updated={updated} refreshable={refreshable} onRefresh={onRefresh} />
            <TableContainer>
               <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
               >
                  <EnhancedTableHead
                     classes={classes}
                     numSelected={selected.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={rows.length}
                  />
                  <TableBody>
                     {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                           const isItemSelected = isSelected(row.id);
                           const labelId = `enhanced-table-checkbox-${index}`;

                           return (
                              <TableRow
                                 hover
                                 onClick={(event) => handleClick(event, row.id)}
                                 role="checkbox"
                                 aria-checked={isItemSelected}
                                 tabIndex={-1}
                                 key={row.id}
                                 selected={isItemSelected}
                              >
                                 <TableCell padding="checkbox">
                                    <Checkbox
                                       checked={isItemSelected}
                                       inputProps={{ "aria-labelledby": labelId }}
                                    />
                                 </TableCell>
                                 <TableCell component="th" id={labelId} scope="row" padding="none">
                                    {row.id}
                                 </TableCell>
                                 <TableCell align="right">{row.order}</TableCell>
                                 <TableCell align="left">{row.description}</TableCell>
                                 <TableCell align="right">{row.slots}</TableCell>
                                 <TableCell align="right">{row.mechanical_bikes}</TableCell>
                                 <TableCell align="right">{row.electrical_bikes}</TableCell>
                                 <TableCell align="right">{row.status}</TableCell>
                              </TableRow>
                           );
                        })}
                     {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onChangePage={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
            />
         </Paper>
         <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
         />
         <FormControlLabel
            control={<Switch checked={cnab} onChange={handleChangeCNAB} />}
            label="CNAB"
         />
         <FormControlLabel
            control={<Switch checked={marbella} onChange={handleChangeMarbella} />}
            label="Marbella"
         />
         <FormControlLabel
            control={<Switch checked={home} onChange={handleChangeHome} />}
            label="Home"
         />
      </div>
   );
}


interface Props {
};


interface State {
   cnab: boolean;
   data: Data[];
   home: boolean;
   marbella: boolean;
   refreshable: boolean;
   updated: string;
}


export default class Bicing extends React.Component<Props, State> {
   private xhr: XMLHttpRequest | undefined;


   constructor(props: Props) {
      super(props);

      this.state = {
         cnab: true,
         data: [],
         home: true,
         marbella: true,
         refreshable: true,
         updated: "",
      };

      this.onCNAB = this.onCNAB.bind( this );
      this.onHome = this.onHome.bind( this );
      this.onMarbella = this.onMarbella.bind( this );
      this.onRefresh = this.onRefresh.bind( this );
   }


   private pull() {
      this.xhr && this.xhr.abort();

      const xhr = this.xhr = new XMLHttpRequest();

      return new Promise((resolve: (data: any | undefined) => void, reject: (reason: any) => void) => {
         xhr.open( "GET", "https://bicing.avaritia.com/stations.json", true ); // NOTE: stations.json is updated via crontab
         //xhr.open( "GET", "http://nld:3000/stations.json", true );
         xhr.onreadystatechange = () => {
            if ( xhr.readyState !== 4 ) return; // Ignore progress or other states for now

            if ( xhr.status >= 400 ) {
               reject( {
                  status: xhr.status,
                  data: xhr.responseText,
               } );
            } else if ( 200 <= xhr.status && xhr.status < 300 ) {
               resolve( xhr.responseText ); // success
            } else {
               // no-op on other
            }
         };
         xhr.onabort = () => reject( { data: "aborted" } );
         xhr.send();
      });
   }


   private refresh() {
      const self = this;

      this.pull().then( json => {
         const data = JSON.parse( json )
         const transformed = data.stations.map( ( station: Data ) => {
            const id = parseInt( station.id );
            station.id = id < 10 ? `00${id}` : ( id < 100 ? `0${id}` : `${id}` );

            return station;
         } );
         const stations = transformed as Data[];

         self.setState( {
            data: stations,
            refreshable: false,
            updated: new Date( data.t * 1000 ).toLocaleTimeString(),
         } );

         setTimeout( () => {
            self.setState( { refreshable: true } )
         }, ( ( 59 - new Date().getSeconds() ) * 1000 + 5000 ) ); // 5s past the minute in order to give cron enough time
      } )
      .catch( e => {
         throw e
      } );
   }


   public onCNAB( show: boolean ) {
      this.setState({
         cnab: show,
      });
   }


   public onHome( show: boolean ) {
      this.setState({
         home: show,
      });
   }


   public onMarbella( show: boolean ) {
      this.setState({
         marbella: show,
      });
   }


   public onRefresh() {
      this.setState( { "updated": "pending" } );
      this.refresh();
   }


   private filter( info: any ) {
      const ids = Object.keys( info );
      const visible: Data[] = this.state.data.reduce( ( previous: Data[], station: Data ) => {
         if ( ids.includes( station.id ) ) {
            station.description = info[station.id].name;
            station.order = info[station.id].order;
            previous.push( station as Data );
         }

         return previous;
      }, [] );

      return visible;
   }


   private cnab() {
      if ( !this.state.data.length || !this.state.cnab ) return [];

      return this.filter( { // HARD-CODED
          "011": { name: "Gym",         order: 60 },
          "012": { name: "Hospital",    order: 70 },
          "031": { name: "Stage",       order: 30 },
          "032": { name: "CNAB",        order: 25 },
          "033": { name: "Restaurants", order: 40 },
          "124": { name: "Maluké",      order: 20 },
          "170": { name: "Bogatell",    order: 80 },
          "397": { name: "Bogatell",    order: 75 },
          "398": { name: "Gym",         order: 60 },
          "400": { name: "W",           order: 10 },
          "424": { name: "Hill",        order: 50 },
      } );
   }


   private home() {
      if ( !this.state.data.length || !this.state.home ) return [];

      return this.filter( { // HARD-CODED
         "037": { name: "Correos",  order: 1 },
         "126": { name: "The Face", order: 2 },
         "377": { name: "IMAX",     order: 3 },
         "401": { name: "Correos",  order: 1 },
         "402": { name: "The Face", order: 2 },
      } );
   }


   private marbella() {
      if ( !this.state.data.length || !this.state.marbella ) return [];

      return this.filter( { // HARD-CODED
          "190": { name: "Marbella",         order: 200 },
          "163": { name: "https://goo.gl/maps/VtSs58koRrZho7nr7", order: 230 },
          "171": { name: "Bogatell",         order: 210 },
          "173": { name: "Eurohotel",        order: 220 },
      } );
   }


   // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#fetching-external-data
   public componentDidMount() {
      this.refresh();
   }


   public render(): JSX.Element {
      if ( !this.state.data.length ) return <div>Loading...</div>;

      const stations = this.state.cnab || this.state.home  || this.state.marbella ? [] : this.state.data;
      const cnab = this.cnab();
      const home = this.home();
      const marbella = this.marbella();
      const visible = stations.concat( cnab, marbella, home );

      return <TableBicing
         data={visible}
         onCNAB={this.onCNAB}
         onHome={this.onHome}
         onMarbella={this.onMarbella}
         updated={this.state.updated}
         refreshable={this.state.refreshable}
         onRefresh={this.onRefresh}
      />;
   }
};
