import "./App.css";
import React from "react";
import Bicing from "./Bicing";


function App() {
   return (
      <div className="App">
         <Bicing />
      </div>
   );
}

export default App;
